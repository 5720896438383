import { Layout } from '@components/layout';
import {
  Hero,
  Products,
  FeaturedText,
  Worth,
  Trust,
  Reviews,
  Ebook,
  PopularUses
} from '@components/standard';
import type { IPage, ILocation, IAcfProductsCategories } from '@standardTypes';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

interface HomeProps {
  pageContext: {
    page: IPage;
    pages: Array<IPage>;
  };
  location: ILocation;
}

const IndexPage: React.FC<HomeProps> = ({ pageContext }) => {
  const { page } = pageContext;

  const { WP } = useStaticQuery<IAcfProductsCategories>(graphql`
    {
      WP {
        page(id: "cG9zdDozMQ==") {
          ACF_ProductsCategories {
            categories {
              name
              path
              image {
                sourceUrl
                srcSet
                slug
              }
            }
          }
        }
      }
    }
  `);

  const { categories } = WP.page.ACF_ProductsCategories;
  console.log('p', page);
  return (
    <Layout {...page}>
      <Hero />
      <Products categories={categories} />
      <Worth />
      <FeaturedText />
      <PopularUses />
      <Reviews />
      <Trust />
      <Ebook />
    </Layout>
  );
};

export default IndexPage;
